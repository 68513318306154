.nav-dropdown-item {
    color: #828db1;
    font-size: 20px;
}

.nav-dropdown-item span {
    text-shadow: 1px 1px 3px rgba(46, 46, 46, 0.1);
}

.custom-dropdown .dropdown-menu {
    left: 0;
    right: auto;
}