.modal-backdrop {
    opacity: 0.2 !important;
}

.modal-footer {
    border-radius: 20px !important;
    border: none;
}

.modal-header {
    border-radius: 20px !important;
    border: none;
}

.modal-content {
    border: 1px solid #d3ddff;
    border-radius: 20px !important;
    box-shadow: 3px 3px 10px rgba(46, 46, 46, 0.62);
}