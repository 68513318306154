

.auth-page {
    width: 100%;
    min-width: 300px;
    max-width: 400px !important;
    padding: 0px 20px 0px 20px !important;
    margin: 0px 0px 0px 0px !important;
}

.auth-form-icon {
    margin-top: 10px !important;
    margin-right: 5px;
}

.form-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
}

.tree-pic {
    height: 200px;
    position: absolute;
    top: -44%;
    left: -13.6%;
}

.bird-pic {
    height: 150px;
    position: absolute;
    top: 90%;
    left: 77%;
}

.login-color {
    width: 100%;
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.login-color-a1 {
    background-color: #df1717 !important;
}

.init-pw-change-modal .modal-backdrop {
    opacity: 0.2 !important;
}

.init-pw-change-modal .modal-footer {
    border-radius: 20px !important;
    border: none;
}

.init-pw-change-modal .modal-header {
    border-radius: 20px !important;
    border: none;
}

.init-pw-change-modal .modal-content {
    border: 1px solid #d3ddff;
    border-radius: 20px !important;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.62);
}

.log-in-button {
    border-radius: 25px;
    width: 100%;
}