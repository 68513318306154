.leaflet-marker-icon {
    background: none;
    border: none;
}

.outlined-text {
    color: #1f3e99;
    font-weight: bolder;
}

.traffic-icon {
    fill: #1f3e99;
}

.popup-buttons {
    position: absolute;
    bottom: 6px;
    right: 8px;
    width: 40px;
    height: 20px;
    display: flex;
    gap: 2px;
}

.popup-button {
    padding: 0px 0px 0px 0px;
    min-width: 50%;
}

.popup-button-icon {
    position: relative;
    bottom: 2px;
}