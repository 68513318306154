.heatmap-tooltip {
    position: absolute;
    width: 200px;
    height: 55px;
    background: #c1c1c1;
    color: black;
    z-index: 25000;
    border-radius: 15px;
    border-style: solid;
    border-width: 3px;
    border-color: #04AA6D;
    padding: 5px;
}