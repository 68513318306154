.selected{
    background-color: rgb(90, 139, 178, 0.5) !important;
}

.box {
    margin: 10px;
    height: 120px;
    text-align: center;
    border-radius: 3px;
    -webkit-transition: 200ms ease-in-out;
    -o-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out;
    -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    cursor: pointer;
    background-color: #e8eef5;
}

.box h1 {
    color: #0c2d8e;
    text-align: center;
    font-weight: 100;
    font-size: 20px;
    background: rgba(0,0,0,0.0);
    -webkit-box-shadow: 0 0 30px rgba(0,0,0,0.7);
    box-shadow: 0 0 30px rgba(0,0,0,0.0);
}

.box .large-h1 {
    font-size: 40px !important;
}
