.back-button:before {
    color: #50649c;
    font-size: 20px;
    padding: 8px 12px;
    position: relative;
    top: 10px;
}

.back-text:hover {

    .back-button {
        transform: scale(1.2);
        filter: drop-shadow(2px 2px 1px rgba(46, 46, 46, 0.62));
    }

}





