.custom-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.numberCircleText {
    font-family: "OpenSans-Semibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    text-align: center;
    line-height: 0px;
    font-size: 24px;
    padding-bottom: 100px;
}

.numberCircle3 {
    margin-top: 10px;
    font-family: "OpenSans-Semibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 0px;
    border-radius: 50%;
    font-size: 2vw;
    min-width: 10vw;
    min-height: 10vw;
    box-shadow: rgb(92, 92, 92) 8px 8px 10px;
}

.numberCircle2 {
    margin-top: 10px;
    font-family: "OpenSans-Semibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 0px;
    border-radius: 50%;
    font-size: 3vw;
    min-width: 10vw;
    min-height: 10vw;
    box-shadow: rgb(92, 92, 92) 8px 8px 10px;
}

.tekstikastid {
    font-weight: 600;
    color: #50649c;
    font-size: 22px;
}


.numberCircle2 span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 1px;
    margin-right: 1px;
}

.numberCircle3 span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 1px;
    margin-right: 1px;
}

/* Some Back Ground Colors */
.clrGreen {
    background: #51a529;
}
.clrRose {
    background: #d31a3b;
}
.clrOrange {
    background: #ec8234;
}
.clrBlue {
    background: #21adfc;
}
.clrMauve {
    background: #7b5d99;
}
