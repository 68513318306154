.profile-container {
    justify-content: center;
}

.profile-card {
    border: 1px solid #d3ddff;
    border-radius: 20px;
    box-shadow: 3px 3px 10px rgba(46, 46, 46, 0.62);
    max-width: 350px;
}

.edit-profile-button {
    background-color: #4d79f6;
    box-shadow: 3px 3px 10px rgba(46, 46, 46, 0.62);
    text-align: center;
}

.profile-groups-title {
    font-size: 16px;
    text-align: center;
}

.profile-groups-table {
    position: relative;
    bottom: 15px;
    border: 2px solid #d3ddff;
    border-radius: 10px !important;
}

.edit-profile-modal .modal-backdrop {
    opacity: 0.2 !important;
}

.edit-profile-modal .modal-footer {
    border-radius: 20px !important;
    border: none;
}

.edit-profile-modal .modal-header {
    border-radius: 20px !important;
    border: none;
}

.edit-profile-modal .modal-content {
    border: 1px solid #d3ddff;
    border-radius: 20px !important;
    box-shadow: 3px 3px 10px rgba(46, 46, 46, 0.62);
}

.swal2-modal {
    border-radius: 20px !important;
}